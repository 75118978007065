import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toggleSignMounting } from 'redux/actions/signsActions'
import StyledMountingPanel from './MountingPanel.style'
import { MOUNTING_TYPES } from 'constants/Signs'

import StyledOptionButtonList from 'components/common/OptionButton/OptionButtonList.style'
import OptionButton from 'components/common/OptionButton/OptionButton'
import Icon from 'components/common/Icon'
import Loader from 'components/common/Loader'

const MountingPanel = (props) => {
  // const { panel, activeSign, activeTab } = props
  const { activeSign } = props
  const dispatch = useDispatch()
  const { prices, isUpdatingSignMounting } = useSelector((state) => {
    return {
      prices: state.app.prices,
      isUpdatingSignMounting: state.editor.isUpdatingSignMounting,
    }
  })

  const activeMountingTypes =
    activeSign && activeSign.values.mounting ? activeSign.values.mounting : []

  const mountingChoices = MOUNTING_TYPES.filter((mounting) => {
    let mountingChoicesStrings = activeSign.settings.mountingChoices.map(
      (choice) => choice.type
    )
    return mountingChoicesStrings.indexOf(mounting.type) > -1
  })

  const options = mountingChoices.map((option) => {
    return {
      ...option,
      isActive: activeMountingTypes.reduce((acc, item) => {
        if (option.type === item.type) {
          acc = true
        }
        return acc
      }, false),
      clickCallback: () => {
        dispatch(toggleSignMounting(activeSign, option))
      },
    }
  })

  if (!activeSign) {
    return null
  }

  return (
    <StyledMountingPanel>
      <StyledMountingPanel.Actions>
        <StyledOptionButtonList>
          {options.map((option, key) => {
            const priceNode = prices[option.type] ? prices[option.type] : null
            return (
              <OptionButton
                key={key}
                isActive={option.isActive}
                name={option.name}
                additionalText={priceNode ? `${priceNode.total} kr` : null}
                clickCallback={() =>
                  !(option.type === 'none' && option.isActive) &&
                  option.clickCallback()
                }
              >
                <Icon name={option.icon} />
              </OptionButton>
            )
          })}

          {isUpdatingSignMounting && (
            <StyledMountingPanel.Loading onClick={(e) => e.stopPropagation()}>
              <StyledMountingPanel.LoadingOverlay />
              <Loader size={20} />
            </StyledMountingPanel.Loading>
          )}
        </StyledOptionButtonList>
      </StyledMountingPanel.Actions>
    </StyledMountingPanel>
  )
}

export default MountingPanel
