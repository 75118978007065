import { getData } from 'helpers/storage'
import { API_BASE } from './Api'
import { headers } from './Customer'
import debounce from 'lodash.debounce'

const CART_ENDPOINT = 'carts'

const checkStatus = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

const getCartEndpoint = (id = null) => {
  return `${API_BASE}/${CART_ENDPOINT}${id ? `/${id}` : ''}`
}

export const fetchCart = async (hash) => {
  const endpoint = getCartEndpoint(hash)

  return await await fetch(endpoint, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const postAddToCart = async (signs) => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/add-items', {
    body: JSON.stringify({
      cartHash: hash,
      items: signs.map(sign => ({
        sign: sign.values,
        id: sign.values.price.id,
        quantity: 1,
        addOnProducts: sign.values.addOnProducts,
      }))
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const postAddAddOnToCart = async (addOn) => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/add-add-on', {
    body: JSON.stringify({
      cartHash: hash,
      addOn,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const postRemoveAddOnsFromCart = async (addOns) => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/remove-add-ons', {
    body: JSON.stringify({
      cartHash: hash,
      addOns
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

// Quantity posted is new quantity, not the number of items to remove
export const postRemoveFromCart = async (items) => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/remove-items', {
    body: JSON.stringify({
      cartHash: hash,
      items,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const postApplyCoupon = async (coupon) => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/apply-coupon', {
    body: JSON.stringify({
      cartHash: hash,
      coupon,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}


export const postRemoveCoupons = async () => {
  const endpoint = getCartEndpoint()
  const hash = getData('cart-hash')

  return await await fetch(endpoint + '/remove-coupons', {
    body: JSON.stringify({
      cartHash: hash,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const postUpdateCartItem = debounce(async (sign) => {
  const endpoint = getCartEndpoint() + '/update-item/' + sign.item_id

  return await await fetch(endpoint, {
    method: 'PUT',
    mode: 'cors', // no-cors, cors, *same-origin
    body: JSON.stringify(sign.values),
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}, 1000)

export const fetchPrices = async () => {
  return await await fetch(`${API_BASE}/prices`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const fetchShipping = async () => {
  return await await fetch(`${API_BASE}/shipping`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}
