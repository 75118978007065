import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

const StyledMountingPanel = styled.div``

StyledMountingPanel.Actions = styled.div`
  position: relative;
`

StyledMountingPanel.Action = styled.div``

StyledMountingPanel.Loading = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`

StyledMountingPanel.LoadingOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${colors.grey01};
  opacity: .5;
`

export default StyledMountingPanel
