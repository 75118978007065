import { curry } from 'helpers/helpers'
import { getSignPrice, getSignAddonProductsPrice } from './signs'
import { postRemoveFromCart } from 'api/Cart'

export const sumFields = curry((key, carry, item) => {
  return carry + item.values.price[key]
})

export const sumPrice = sumFields('price')
export const sumTax = sumFields('tax')
export const sumTotal = sumFields('total')

export const getCartTotal = (signs) => {
  return signs.reduce((acc, sign) => {
    const { total } = getSignPrice(sign)
    const addOnProductsTotal = getSignAddonProductsPrice(sign)
    return acc + total + addOnProductsTotal
  }, 0)
}

export const removeFromCart = async (activeSigns, wcCart, cartSigns) => {
  const removeItems = wcCart.items.map(item => {
    const signsInCart = activeSigns.filter((sign) =>
      signIsInCart(sign, cartSigns)
    )
    const signs = signsInCart.filter(sign => sign.cartItem?.id === item.id)
    const addOnQuantity = signsInCart.flatMap(sign => sign.values.addOnProducts.filter(addOn => addOn.id === item.id)).length

    if (signs.length === 0 && addOnQuantity === 0) {
      return undefined
    }

    const returnItem = {
      id: item.id,
      key: item.key,
    }

    return signs.length ? {
      ...returnItem,
      quantity: item.quantity - signs.length,
      signs,
    } : {
      ...returnItem,
      quantity: item.quantity - addOnQuantity,
    }
  }).filter(item => item !== undefined)

  const response = await postRemoveFromCart(removeItems)

  return response
}

export const getActiveItemSigns = (signs, activeItems, cartSigns) => {
  return signs.filter((sign) => activeItems.includes(sign.values.id)).map(sign => {
    return cartSigns.find(cartSign => sign.values.id === cartSign.values.id) ?? sign
  })
}

export const signIsInCart = (sign, cartSigns) => {
  return (
    cartSigns?.some((cartSign) => cartSign.values.id === sign.values.id) ??
    false
  )
}
