import { TABLE_STAGE } from 'constants/Editor'
import { useDispatch, useSelector } from 'react-redux'
import StyledAddToCartButton from './style'
import { useResponsive } from 'hooks/useResponsive'
import { postAddToCart } from 'api/Cart'
import { updateCart } from 'redux/actions/appActions'
import { useState } from 'react'
import Loader from '../Loader'
import { getActiveItemSigns, removeFromCart } from 'helpers/cart'
import { unsetItemIds } from 'redux/actions/signsActions'

const stateSelector = (state) => {
  return {
    signs: state.signs.present.signs,
    editor: state.editor,
    activeItem: state.editor.activeItem,
    activeStage: state.editor.activeStage,
    cartSigns: state.cart.signs,
    wcCart: state.cart.wcCart,
    prices: state.app.prices,
  }
}

const AddToCartButton = () => {
  const dispatch = useDispatch()
  const { breakpoints } = useResponsive()
  const [isLoading, setIsLoading] = useState(false)
  const { signs, editor, activeItem, activeStage, cartSigns, wcCart } =
    useSelector(stateSelector)

  const singleActiveItem = activeItem ? [activeItem] : [] // Check if there is an activeItem. We dont want an array like [0]
  const activeItems =
    activeStage === TABLE_STAGE && editor.bulkEdit.length > 0
      ? editor.bulkEdit
      : singleActiveItem

  const handleAddToCart = async () => {
    setIsLoading(true)
    const response = await postAddToCart(getActiveItemSigns(signs, activeItems, cartSigns))

    if (response?.cart) {
      dispatch(updateCart(response))
    }
    setIsLoading(false)
  }

  const handleRemoveFromCart = async () => {
    setIsLoading(true)

    const activeSigns = getActiveItemSigns(signs, activeItems, cartSigns)
    const response = await removeFromCart(activeSigns, wcCart, cartSigns)

    if (response?.cart) {
      dispatch(unsetItemIds(activeSigns))
      dispatch(updateCart(response))
    }

    setIsLoading(false)
  }

  const inCart = cartSigns.some((cartSign) =>
    activeItems.some((itemId) => cartSign.values.id === itemId)
  )

  return (
    <StyledAddToCartButton
      type={'teal-light light'}
      handleButtonClick={inCart ? handleRemoveFromCart : handleAddToCart}
      icon={isLoading || breakpoints.mobileDown ? undefined : 'import'}
      disabled={isLoading || signs.length === 0}
    >
      {isLoading ?
        <Loader size={20} /> :
        inCart ? 'Ta bort från varukorg' : 'Lägg till i varukorg'
      }
    </StyledAddToCartButton>
  )
}

export default AddToCartButton
