import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { duplicateSignsNoText, addSigns } from 'redux/actions/signsActions'
import { addToastWithTimer } from 'redux/actions/toastActions'
import { cloneSignWithNewType, duplicateSign, newSignTemplate } from 'helpers/signs'
import { SIGN_TYPES } from 'constants/Signs'
import Cookies from 'js-cookie'

export const EDITOR_SET_ACTIVE_ITEM = 'EDITOR_SET_ACTIVE_ITEM'
export const setActiveEditorItem = (payload) => {
  return async (dispatch) => {
    await dispatch({ type: EDITOR_SET_ACTIVE_ITEM, payload: payload })
  }
}

export const setNewestItemToActive = () => {
  return async (dispatch, getState) => {
    const { signs } = getState()
    const newestSign = signs.present.signs[signs.present.signs.length - 1]
    await dispatch({ type: EDITOR_SET_ACTIVE_ITEM, payload: newestSign })
  }
}

export const EDITOR_SET_ACTIVE_STAGE = 'EDITOR_SET_ACTIVE_STAGE'
export const setActiveStage = (stage) => {
  Cookies.set('stage_type', stage)
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_SET_ACTIVE_STAGE,
      payload: stage,
    })
  }
}

export const EDITOR_TOGGLE_TOOLBAR_ITEM_OPEN = 'EDITOR_TOGGLE_TOOLBAR_ITEM_OPEN'
export const toggleToolbarItemOpen = (id, closeOthers) => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_TOGGLE_TOOLBAR_ITEM_OPEN,
      payload: {
        id,
        closeOthers,
      },
    })
  }
}

export const EDITOR_TOGGLE_TOOLBAR_ITEM_TAB = 'EDITOR_TOGGLE_TOOLBAR_ITEM_TAB'
export const toggleToolbarItemTab = (id) => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_TOGGLE_TOOLBAR_ITEM_TAB,
      payload: id,
    })
  }
}

export const EDITOR_ADD_TO_BULK_EDIT = 'EDITOR_ADD_TO_BULK_EDIT'
export const addToBulkEdit = (id) => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_ADD_TO_BULK_EDIT,
      payload: id,
    })
  }
}

export const EDITOR_REMOVE_FROM_BULK_EDIT = 'EDITOR_REMOVE_FROM_BULK_EDIT'
export const removeFromBulkEdit = (id) => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_REMOVE_FROM_BULK_EDIT,
      payload: id,
    })
  }
}

export const EDITOR_ACTIVATE_BULK_EDITOR = 'EDITOR_ACTIVATE_BULK_EDITOR'
export const activateBulkEditor = () => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_ACTIVATE_BULK_EDITOR,
    })
  }
}

export const EDITOR_DEACTIVATE_BULK_EDITOR = 'EDITOR_DEACTIVATE_BULK_EDITOR'
export const deactivateBulkEditor = () => {
  return async (dispatch) => {
    await dispatch({
      type: EDITOR_DEACTIVATE_BULK_EDITOR,
    })
  }
}

export const EDITOR_FOCUS_TABLE_TEXT_INPUT = 'EDITOR_FOCUS_TABLE_TEXT_INPUT'
export const focusOnTableTextInput = ({ sign, rowId }) => {
  return async (dispatch) => {
    await dispatch(setActiveEditorItem(sign))
    await dispatch({
      type: EDITOR_FOCUS_TABLE_TEXT_INPUT,
      payload: {
        sign,
        rowId,
      },
    })
  }
}

export const duplicateSignsWithMessage = (ids, message) => {
  return async (dispatch) => {
    await dispatch(duplicateSignsNoText(ids))
    await dispatch(setNewestItemToActive())
    await dispatch(
      addToastWithTimer({
        id: Math.floor(Math.random() * 10000 + 1),
        message: message,
        type: 'info',
      })
    )
  }
}

export const createSignsFromSpreadsheetData = (data) => {
  return async (dispatch, state) => {
    const prices = state().app.prices

    let signsToAdd = []

    data.forEach((dataRow, key) => {
      let type = dataRow[0]

      // Check if type is valid
      const validType = SIGN_TYPES.filter((signType) => signType.type === type)

      if (validType.length) {
        // Check how many text-rows are allowed on specific type ( key and mark only one row )
        let numRows =
          type === SIGN_TYPES[0].type || type === SIGN_TYPES[2].type ? 2 : 4

        // Create proper text-nodes from data
        let texts = dataRow.slice(1, numRows).map((text, index) => {
          return {
            rowId: index,
            content: text,
          }
        })

        // Create complete sign from data
        const newSign = cloneSignWithNewType(newSignTemplate, type, prices)

        const completeSign = {
          ...newSign,
          values: {
            ...newSign.values,
            text: [...texts],
          },
        }

        // And duplicate to get unique ID
        let duplicate = duplicateSign(completeSign)

        signsToAdd.push(duplicate)
        // dispatch(addSign(duplicate))
      } else {
        // console.log('wrong signtype in csv row')
      }
    })

    dispatch(addSigns(signsToAdd))
  }
}

export const undoLastAction = () => {
  return UndoActionCreators.undo()
}

export const redoLastAction = () => {
  return UndoActionCreators.redo()
}

export const SET_IS_UPDATING_SIGN_MOUNTING = 'SET_IS_UPDATING_SIGN_MOUNTING'
export const setIsUpdatingSignMounting = (isUpdating) => ({
  type: SET_IS_UPDATING_SIGN_MOUNTING,
  payload: isUpdating,
})
